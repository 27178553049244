<template>
  <div>
    <p class="mb-0 mt-2 font-weight-bold">Los archivos solo pueden ser *.xls o *.xlsx y el nombre debe cumplir con el siguiente formato:</p>
    <p class="font-weight-bold text-danger">[NÚMERO FACTURA]_[ID EMPRESA ENVÍAME]</p>
    <div class="row" :key="InvoiceAreaRender">
      <div class="mt-0 pt-0 col-12 col-md-9">
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="form.fileInvoices" v-cloak>
          <p class="mb-0"><small>{{form.fileInvoices.length}} archivos</small></p>
          <div class="w-100 p-2 file-drop-area">
            <b-input-group v-for="(field, index) in form.fileInvoices" :key="`${index}_fileInvoices`" class="invoice-list-area">
              <b-form-input
                  placeholder="File name..."
                  v-model="field.name"
                  class="bg-gray-input border-0"
              />
              <b-input-group-append class="bg-gray-input">
                <b-button variant="outline" class="border-0" size="sm" @click="removeFileInvoices(index)" :disabled="loading.uploadData">
                  <feather-icon icon="XIcon" size="20"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="!form.fileInvoices">
          <div class="file-drop-area" >
            <input type="file" multiple name="files" id="files" class="file-input invisible" @change="changeFileInvoices" accept=".xls, .xlsx" :disabled="loading.uploadData"/>
            <label for="files" class="w-100 text-center cursor-pointer m-0">
              <feather-icon v-if="!loading.uploadData" icon="UploadCloudIcon" size="30" class="mt-1" />
              <p v-if="!loading.uploadData" class="mb-1">Subir Archivo</p>
              <p class="mt-1 p-2" v-if="loading.uploadData"><b-spinner small  /> Enviando</p>
            </label>
          </div>
        </b-col>
      </div>
      <div class="col-md-3 d-flex flex-column align-items-center justify-content-center">
        <input type="file" id="aditionalInvoice" multiple name="aditionalInvoice" @change="changeFileInvoices" :disabled="loading.uploadData" accept=".xls, .xlsx" hidden/>
        <label for="aditionalInvoice" v-if="form.fileInvoices && !loading.uploadData" class="add-adition-invoices mb-2">
          <b-badge>Nueva carga</b-badge>
        </label>
        <b-button class="" block @click="uploadInvoices" variant="success" :disabled="loading.uploadData || !form.fileInvoices">
          <b-spinner v-if="loading.uploadData" small></b-spinner>
          <span v-else>Enviar archivos</span>         
        </b-button>
      </div>
    </div>
    <div class="my-2">
      <b-progress height="2rem" v-if="loading.uploadData" :max="form.fileInvoices.length">
        <b-progress-bar :value="loadData" animated variant="success">
          <span>Enviando: <strong>{{ loadData }} de {{ totalData }}</strong>... esperando respuesta.</span>
        </b-progress-bar>
      </b-progress>
    </div>
    <div v-if="fileWithError">
      <p class="text-primary font-weight-bold">Listado de archivos que no pudieron ser guardados. Total: {{rows.length}}/{{ totalUploadFiles }}</p>
      <div class="error-table-container">
        <table-render :key="keyTableRender" :rows="rows" :schema="schema"/>
      </div>
    </div>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'

export default {
  name: 'upload-invoices-manage',
  props: ['closeModal', 'blockTabs', 'selectedCountry'], 
  data() {
    return {
      InvoiceAreaRender: 0,
      loadData: 0,
      totalData: 0,
      keyTableRender: 0,
      schema: [
        {label: 'Archivo', sortable: true, key: 'file', style: { width: '30%' }},
        {label: 'Detalle', key: 'msg'}
      ],
      rows: [],
      fileWithError: false,
      form: {
        fileInvoices: null
      },
      loading: {
        uploadData: false
      },
      baseService: new BaseServices(this),
      stopPosition: [],
      totalUploadFiles: 0,
      byteMaxForFile: 30000000,
      byteMinForFile: 1,
      country: {}
    }
  },
  watch: {
    selectedCountry () {
      this.country = this.selectedCountry
    }
  },
  mounted() {
    this.country = this.selectedCountry
  },
  methods: {
    changeFileInvoices(e) {
      let canISave = true
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > this.byteMaxForFile || e.target.files[i].size < this.byteMinForFile) {
          canISave = false
          this.showModalError(`Carga interrumpida por archivo con tamaño superior a ${this.$options.filters.formatByte(this.byteMaxForFile)} o menor a ${this.$options.filters.formatByte(this.byteMinForFile)}.<br>Nombre del archivo: ${e.target.files[i].name}, tamaño: ${ this.$options.filters.formatByte(e.target.files[i].size || 0)}`)
          return
        }
      }
      if (canISave) {
        this.form.fileInvoices = e.target.files
        this.totalData = this.form.fileInvoices?.length
        this.InvoiceAreaRender++
      }
    },
    uploadInvoices () {
      this.blockParentTab(true)
      if (!this.validateFile(this.form.fileInvoices)) {
        this.blockParentTab(false)
        return
      }
      this.getSplitLoteBySize() //obtener los tamaños de los lotes en base al size del lote para evitar romper la gae
      if (this.fileWithError) { // para eliminar los datos de error en caso de que suban nuevamente
        this.rows = []
        this.fileWithError = false
      }
      this.totalUploadFiles = this.totalData
      this.loading.uploadData = true
      this.uploadPartialBills(0, true)
    },
    getSplitLoteBySize () {
      let totalSize = 0
      let totalLote = -1
      for (let i = 0; i < this.form.fileInvoices.length; i++) {
        totalSize += this.form.fileInvoices[i].size
        totalLote++
        if ((totalSize >= this.byteMaxForFile || totalSize <= this.byteMinForFile) || totalLote === 200) { 
          this.stopPosition.push(i)
          totalSize = 0
          totalLote = 0
        }
      }
    },
    uploadPartialBills (page, fistLoop) {
      const min = fistLoop ? 0 : this.stopPosition[page - 1]
      const data = {
        'files[]': Array.from(this.form.fileInvoices).slice(min, this.stopPosition[page]),
        'countryCode': this.country.code
      }
      this.loadData += data['files[]'].length
      this.baseService.callUploadFileCustomParams('uploadInvoicesGroupBulk', data)
        .then(response => {
          page++
          response.data.map((el, index) => {
            if (el.message) {
              this.rows.push({ msg: el.message, file: data['files[]'][index].name})
              this.fileWithError = true
            } else if (el.error) {
              this.rows.push({ msg: el.error, file: data['files[]'][index].name})
              this.fileWithError = true
            } 
          })
          if (this.form.fileInvoices.length === this.loadData) {
            if (this.fileWithError) {
              if (this.form.fileInvoices.length === this.rows.length) {
                this.$alert('Los archivos enviados no han sido guardados, por favor verificar en la tabla.')
              } else {
                this.$warning('Importación realizada excluyendo los archivos con errores, por favor verificar en la tabla.', () => null, 'Completado con exclusión', 'Revisar')
              }
              this.form.fileInvoices = null
            } else {
              this.$success(this.$t('msg-exito-importar-archivo'))
              this.close()
            }
            this.loading.uploadData = false
            this.blockParentTab(false)
            this.loadData = 0
          } else {
            this.uploadPartialBills(page, false)
          }
        })
        .catch((err) => {
          // Algunos condiciones para dar mas luz con los detalles de los errores
          const hasError = this.fileWithError ? `<br><br>Tomar en cuenta que ${this.rows.length} archivos de los enviados tienen errores y no fueron guardados` : ''
          const errorMsg = err.message ? `<br><br><pre>${err?.message}</pre>` : ''
          const errorDescrip = `Detalles: Total de archivos enviados: ${this.loadData} de ${this.form.fileInvoices.length}${hasError}${errorMsg}`
          this.$alert(errorDescrip, () => null, 'Alerta al cargar este lote')
          this.loading.uploadData = false
          this.blockParentTab(false)
          this.loadData = 0
        })
    },
    validateFile (files) {
      const regx = /^[0-9_]*$/
      if (!files) {
        this.showModalError('Debes seleccionar al menos un archivo para subir')
        return false
      }
      for (let i = 0; i < files.length; i++)
      {
        if (files[i].name.split('.')[0].split('_').length < 2 || !regx.test(files[i].name.split('.')[0])) {
          this.showModalError('Al menos uno de los archivos subidos no cuenta con la estructura correcta en el nombre')
          return false
        }
      }
      return true
    },
    showModalError (detail) {
      this.$alert(detail)
      this.loading.uploadData = false
    },
    blockParentTab (val) {
      this.$emit('blockTabs', val)
    },
    removeFileInvoices(index) {
      const dt = new DataTransfer()

      for (let i = 0; i < this.form.fileInvoices.length; i++) {
        if (index !== i) dt.items.add(this.form.fileInvoices.item(i))
      }
      this.form.fileInvoices = dt.files
      if (this.form.fileInvoices?.length === 0) this.form.fileInvoices = null      
      this.totalData = this.form.fileInvoices?.length
      this.InvoiceAreaRender++
    },
    close() {
      this.form.fileInvoices = null
      this.loading.uploadData = false
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  div.file-drop-area {
    max-height: 150px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .invoice-list-area{
    padding: 0 5px 8px 0;
    width: 50%;

    input {
      font-size: 0.9rem;
    }
  }
  .bg-gray-input {
    background-color: #f7f7f9 !important;
  }
  .add-adition-invoices{
    width: 100%;
    span {
      width: 100% !important;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.8rem;
    }
  }
 .error-table-container{
    max-height: 200px !important;
    overflow-y: auto;
    overflow-x: hidden !important;
 }
</style>