<template>
  <b-modal
    id="modal-recaudos"
    modal-class="dialog-800"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    class="modal-recaudos"
    @close="close"
  >
    <template #modal-title>
      <h3>{{$t('Recaudos entrega')}}</h3>
    </template>
    <form-render
      ref="formRenderStatusCompany"
      :form.sync="formCountry"
      :fields="fieldsCountry"
      :key="keyFormRenderCountryRed"
    />
    <b-card>
      <b-tabs justified class="tab-primary" v-model="tabIndex" @activate-tab="activatedEvent">
        <b-tab>
          <template #title>
            <div :class="[!allow.carrierColletions ? 'blocked-element' : '', 'tab-title']">
            <span>{{$t('Importar recaudos')}}</span>
            </div>
          </template>
          <div @click="e => handleClickOnComponent(e, allow.carrierColletions)" :class="!allow.carrierColletions ? 'blocked-element' : ''">
            <upload-collection-carrier v-bind:disabled="true" :selectedCountry="selectCountry" @closeModal="closeModal" @blockTabs="blockTabs"/>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="[!allow.paymentsColletions ? 'blocked-element' : '', 'tab-title']">
            <span>{{$t('Importar pagos')}}</span>
            </div>
          </template>
          <div @click="e => handleClickOnComponent(e, allow.paymentsColletions)" :class="!allow.paymentsColletions ? 'blocked-element' : ''">
            <upload-collection-payments v-bind:disabled="true" :selectedCountry="formCountry.country" @closeModal="closeModal" @blockTabs="blockTabs"/>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import uploadCollectionCarrier from './components/collections/uploadCollectionCarrier.vue'
import uploadCollectionPayments from './components/collections/uploadCollectionPayments.vue'

export default {
  name: 'modal-recaudos',
  components: { uploadCollectionCarrier, uploadCollectionPayments },
  data() {
    return {
      formCountry: {},
      selectCountry: {},
      fieldsCountry: [],
      allow: {
        carrierColletions: true,
        paymentsColletions: true
      },
      allowedTabsByCountry: Object.freeze({
        CL: [],
        MX: ['carrierColletions', 'paymentsColletions'],
        CO: ['carrierColletions', 'paymentsColletions'],
        PE: [],
        AR: [],
        EC: []
      }),
      tabIndex: 0,
      keyFormRenderCountryRed: 0,
      callingService: false 
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries'
    })
  },
  watch: {
    countries () {
      this.setCountry()
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fieldsCountry = [
        {fieldType: 'FieldSelect', label: this.$t('País'), name: 'country', addFlags:true, useLabel: true, clearable:false, containerClass: 'col-md-4 container-info col-sm-6', change: this.checkAvailability}
      ]
      if (!!this.countries.length) this.setCountry()
    },  
    setCountry () {
      this.setSelectOptions('country', { options: this.countries})
      this.formCountry.country = this.countries.find(item => item.code === 'CO')
      this.selectCountry = this.formCountry.country
      this.checkAvailability(false)
    },
    handleClickOnComponent (event, click) {
      if (click) return
      event.preventDefault()
    },
    checkAvailability (onChange = true) {
      if (onChange) this.selectCountry = this.formCountry.country
      Object.keys(this.allow).forEach(key => {
        this.allow[key] = this.allowedTabsByCountry[this.selectCountry?.code].includes(key)
      })
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsCountry.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsCountry[index].options = options
        this.keyFormRenderCountryRed++
      }
    },
    activatedEvent (newT, prevT, bvEvent) {
      if (this.callingService || !Object.values(this.allow)[newT]) bvEvent.preventDefault()
    },
    close(bvModal) {
      if (this.callingService) bvModal.preventDefault()
    },
    closeModal () {
      this.$bvModal.hide('modal-recaudos')
    },
    blockTabs(bool) {
      this.callingService = bool
    }
  }
}
</script>
