<template>
  <div>
    <p class="mb-0">Cargue aquí el archivo con los datos de recaudos pagados.</p>
    <p class="font-weight-bold">El archivo solo puede ser *.xls o *.xlsx, según el siguiente <a class="lnk lnk-primary" :href="excelDocUrl" target="_blank" rel="noopener noreferrer">excel de ejemplo con formato válido.</a></p>
    <div class="row" >
      <div class="mt-0 pt-0 col-12 col-md-9">
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="form.fileCollectionPayments" v-cloak>
          <div class="w-100 p-2 file-drop-area-payment">
            <b-input-group class="collection-payment-area">
              <b-form-input
                  placeholder="File name..."
                  v-model="form.fileCollectionPayments.name"
                  class="bg-gray-input border-0"
              />
              <b-input-group-append class="bg-gray-input">
                <b-button variant="outline" class="border-0" size="sm" @click="removeFileCollectionPayments()" :disabled="loading.uploadCollectionPayments">
                  <feather-icon icon="XIcon" size="20"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="!form.fileCollectionPayments">
          <div class="file-drop-area-payment" >
            <input type="file" name="fileCollectionPayments" id="fileCollectionPayments" class="file-input invisible" @change="changeFileDataToUpdate" accept=".xls, .xlsx"/>
            <label for="fileCollectionPayments" class="w-100 text-center cursor-pointer m-0">
              <feather-icon v-if="!loading.uploadCollectionPayments" icon="UploadCloudIcon" size="30" class="mt-1" />
              <p v-if="!loading.uploadCollectionPayments" class="mb-1">Subir Archivo</p>
              <p class="mt-1 p-2" v-if="loading.uploadCollectionPayments"><b-spinner small  /> {{ $t('Enviando') }}</p>
            </label>
          </div>
        </b-col>
      </div>
      <div class="col-12 mt-1">
        <form-render
          ref="formRenderCollectionPayments"
          :form.sync="formCollectionPayments"
          :fields="fieldsCollectionPayments"
          containerButtonsClass="col-sm-12 col-md-6 mt-2"
          :key="keyFormRenderCollectionPayments"
        >
          <template #buttons>
            <div class="float-right">
              <b-button block @click="uploadCollectionPaymentsFile()" variant="success" :disabled="loading.uploadCollectionPayments || !form.fileCollectionPayments || !formCollectionPayments.date" class="px-md-3">
                <b-spinner v-if="loading.uploadCollectionPayments" small></b-spinner>
                <span v-else>Enviar</span>
              </b-button>
            </div>
          </template>
        </form-render>
      </div>
      <div class="col mt-2 d-flex justify-content-end text-right">
        <b-form-group>
          <span></span>
          <b-form-radio-group
            id="radio-update-payments"
            v-model="selectedOptionsUpdating"
            :options="optionsUpdating"
            name="update-payments"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>
    <div class="px-2" v-if="fileWithError">
      <p class="text-primary font-weight-bold pt-2">Listado de errores en el documento</p>
      <div class="error-table-container">
        <table-render :key="keyTableRenderPayments" :rows="rowsErrors" :schema="schema"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseServices from '@/store/services/index'
import BillingService from '../../../../billing.service'


export default {
  name: 'upload-collection-payments',
  props: ['closeModal', 'blockTabs', 'selectedCountry'],
  data() {
    return {
      form: {
        fileCollectionPayments: null
      },
      country: {},
      excelDocUrl: null,
      fileWithError: false,
      formCollectionPayments: {},
      keyFormRenderCollectionPayments: 0,
      fieldsCollectionPayments: [
        { fieldType: 'FieldDatepicker', label: this.$t('Fecha de pago'), name: 'date', placeholder:'Seleccione fecha de pago', clearable: true, validation: 'required', containerClass: 'container-info col-md-6'}
      ],
      keyTableRenderPayments: 0,
      schema: [
        {label: 'Error', key: 'title_error'},
        {label: 'Detalle', key: 'detail', useDangerHTML: true}
      ],
      rowsErrors: [],
      loading: {
        uploadCollectionPayments: false
      },
      fields: [],
      optionsUpdating: [
        { text: 'Solo validar excel', value: 'validateOnly' },
        { text: 'Generar reporte', value: 'updateData' },
        { text: 'Generar reporte y guardar datos', value: 'default' }
      ],
      selectedOptionsUpdating: 'default',
      baseService: new BaseServices(this),
      BillingService: new BillingService(this),
      countryDownloadLinks: Object.freeze({
        CO: '/formato-importacion-pagos-de-recaudos.xlsx',
        MX: '/formato-importacion-pagos-de-recaudos-mexico.xlsx'
      })
    }
  },
  watch: {
    selectedCountry () {
      this.setDownloadLink(this.selectedCountry.code)
      this.country = this.selectedCountry
    }
  },
  mounted() {
    if (this.selectedCountry) this.setDownloadLink(this.selectedCountry.code)
    this.country = this.selectedCountry
  },
  methods: {
    uploadCollectionPaymentsFile () {
      if (!this.form.fileCollectionPayments) {
        this.$alert('Debes seleccionar un archivo para subir ')
        return
      }
      this.blockParentTab(true)
      this.clearError()
      this.loading.uploadCollectionPayments = true
      const isOnlyValidate = this.selectedOptionsUpdating === 'validateOnly'
      const isUpdateDate = this.selectedOptionsUpdating === 'updateData'
      const data = {
        file: this.form.fileCollectionPayments,
        countryCode: this.country.code,
        paymentDate: this.$options.filters.moment(this.formCollectionPayments.date, 'YYYY-MM-DD'),
        updateData: isUpdateDate || isOnlyValidate ? 0 : 1,
        validateOnly: isOnlyValidate ? 1 : 0
      }

      this.baseService.callUploadFileCustomParams('uploadCollectionPaymentsBillingData', data)
        .then(response => {
          const html = `<p>${this.$t('msg-exito-importar-archivo')}</p><p>Código: ${response.meta.deliveryImportBatch.code} </p>`
          this.$success(this.$t(isOnlyValidate ? 'msg-exito-validar-archivo' : html))
          this.removeFileCollectionPayments()
          this.close()
        })
        .catch((err) => {
          if (err?.errors) {
            this.$alert(this.$t('msg-problema-cargar-datos-list-errors', {code: 'Verificar listado de errores'}))
            err.errors.forEach((e) => {
              let message = ''
              Array.isArray(e.detail) ? e.detail.forEach(msg => message += `${msg}.<br>`) : message = e.detail
              this.rowsErrors.push({ title_error: e.title, detail: message })
            })
            this.fileWithError = true
          } else {
            this.$alert(this.$t('msg-problema-cargar-datos-no-code'))
          }
        })
        .finally(() => {
          this.loading.uploadCollectionPayments = false
          this.blockParentTab(false)
        })
    },
    setDownloadLink (code) {
      this.excelDocUrl = `${this.BillingService.getCustomEnvironment('newyorkDocsCloud')}/excel-sample-delivery-import-collection-payments${this.countryDownloadLinks[code]}`
    },
    changeFileDataToUpdate(e) {
      this.form.fileCollectionPayments = e.target.files[0]
    },
    removeFileCollectionPayments() {
      this.form.fileCollectionPayments = null
      this.keyTableRenderPayments++
      this.clearError()
    },
    blockParentTab (val) {
      this.$emit('blockTabs', val)
    },
    clearError() {
      if (this.fileWithError) {
        this.rowsErrors = []
        this.fileWithError = false
      }
    },
    close() {
      this.removeFileCollectionPayments()
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
div.file-drop-area-payment {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  max-height: 300px;
  overflow-y: auto;
  input.file-input {
    position: absolute;
    height: 0;
  }
}
.collection-payment-area{
  padding-bottom: 8px;
  input {
    font-size: 0.9rem;
  }
}
.bg-gray-input {
  background-color: #f7f7f9 !important;
}
.error-table-container{
  max-height: 200px !important;
  overflow-y: auto;
  overflow-x: hidden !important;
}
</style>
