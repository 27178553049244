<template>
  <b-modal
    id="modal-upload-files"
    modal-class="dialog-800"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @close="close"
    class="modal-upload-files"
  >
    <template #modal-title>
        <h3>Ciclo de facturación</h3>
    </template>
    <b-card>
      <form-render
        ref="formRenderStatusCompany"
        :form.sync="formCountry"
        :fields="fieldsCountry"
        :key="keyFormRenderCountry"
      />
      <b-tabs justified class="tab-primary" v-model="tabIndex" @activate-tab="activatedEvent">
        <b-tab>
          <template #title>
            <div :class="[!allow.cost ? 'blocked-element' : '', 'tab-title']">
              <span>{{$t('Importar costos distribución')}}</span>
            </div>
          </template>
          <div @click="e => handleClickOnComponent(e, allow.cost)" :class="!allow.cost ? 'blocked-element' : ''">
            <upload-cost-manage v-bind:disabled="true" @closeModal="closeModal" @blockTabs="blockTabs" :selectedCountry="formCountry.country"/>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="[!allow.exportService ? 'blocked-element' : '', 'tab-title']">
              <span>{{$t('Exportar otros servicios')}}</span>
            </div>
          </template>
          <div @click="e => handleClickOnComponent(e, allow.exportService)" :class="!allow.cost ? 'blocked-element' : ''">
            <export-service v-bind:disabled="true" @closeModal="closeModal" @blockTabs="blockTabs" :selectedCountry="formCountry.country"/>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="[!allow.invoices ? 'blocked-element' : '', 'tab-title']">
            <span> {{$t('Detalles de facturación')}} </span>
            </div>
          </template>
          <div @click="e => handleClickOnComponent(e, allow.invoices)" :class="!allow.invoices ? 'blocked-element' : ''">
            <upload-invoices-manage @closeModal="closeModal" @blockTabs="blockTabs" :selectedCountry="formCountry.country"/>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="[!allow.nofications ? 'blocked-element' : '', 'tab-title']">
            <span>{{$t('Notificaciones')}}</span>
            </div>
          </template>
          <div @click="e => handleClickOnComponent(e, allow.invoices)" :class="!allow.nofications ? 'blocked-element' : ''">
            <send-notification-manage @closeModal="closeModal" @blockTabs="blockTabs" :selectedCountry="formCountry.country"/>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="[!allow.indemnifications ? 'blocked-element' : '', 'tab-title']">
            <span> {{$t('Indemnizaciones')}} </span>
            </div>
          </template>
          <div @click="e => handleClickOnComponent(e, allow.invoices)" :class="!allow.indemnifications ? 'blocked-element' : ''">
            <upload-indemnification-manage @closeModal="closeModal" @blockTabs="blockTabs" :selectedCountry="formCountry.country"/>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import uploadCostManage from './components/billing-cycle/uploadCost.vue'
import uploadInvoicesManage from './components/billing-cycle/uploadInvoices.vue'
import sendNotificationManage from './components/billing-cycle/SendNotification.vue'
import uploadIndemnificationManage from './components/billing-cycle/uploadIndemnification.vue'
import ExportService from './components/billing-cycle/exportServices.vue'

export default {
  name: 'modal-upload-files',
  components: { uploadCostManage, uploadInvoicesManage, sendNotificationManage, uploadIndemnificationManage, ExportService },

  data() {
    return {
      formCountry: {},
      fieldsCountry: [],
      keyFormRenderCountry: 0,
      tabIndex: 0,
      callingService: false,
      allow: {
        cost: true,
        invoices: true,
        nofications: true,
        indemnifications: true,
        exportService: true
      },
      allowedTabsByCountry: Object.freeze({
        CL: ['cost', 'invoices', 'exportService', 'nofications', 'indemnifications'],
        MX: ['cost'],
        CO: ['cost'],
        PE: ['cost'],
        AR: [],
        EC: []
      })
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries'
    })
  },
  watch: {
    countries () {
      this.setCountry()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fieldsCountry = [
        {fieldType: 'FieldSelect', label: this.$t('País'), name: 'country', addFlags:true, useLabel: true, clearable:false, containerClass: 'col-md-4 container-info col-sm-6', change: this.checkAvailability}
      ]
      if (!!this.countries.length) this.setCountry()
    },
    setCountry () {
      this.setSelectOptions('country', { options: this.countries})
      this.formCountry.country = this.countries.find(item => item.code === 'CL')
      this.checkAvailability()
    },
    handleClickOnComponent (event, click) {
      if (click) return
      event.preventDefault()
    },
    checkAvailability () {
      Object.keys(this.allow).forEach(key => {
        this.allow[key] = this.allowedTabsByCountry[this.formCountry?.country.code].includes(key)
      })
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsCountry.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsCountry[index].options = options
        this.keyFormRenderCountry++
      }
    },
    activatedEvent (newT, prevT, bvEvent) {
      if (this.callingService || !Object.values(this.allow)[newT]) bvEvent.preventDefault()
    },
    close(bvModal) {
      if (this.callingService) bvModal.preventDefault()
    },
    closeModal () {
      this.$bvModal.hide('modal-upload-files')
    },
    blockTabs(bool) {
      /*******
      * Para bloquear algunos funcionamientos por defecto como:
      * 1- No permitir la activacion de las tabs
      * 2- No permitir cerrar el modal
      ********/
      this.callingService = bool
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-upload-files{
    .card {
      min-height: 500px;
      background-color: red !important;
    }
  }
</style>
