<template>
  <b-modal
    id="modal-masive-update"
    modal-class="dialog-800"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    class="modal-masive-update"
    @close="close"
  >
    <template #modal-title>
      <h3>Datos facturación</h3>
    </template>
    <form-render
      ref="formRenderStatusCompany"
      :form.sync="formCountry"
      :fields="fieldsCountry"
      :key="keyFormRenderCountryUpd"
    />
    <b-card>
      <div :class="[!allow.updateData ? 'blocked-element' : '']"  @click="e => handleClickOnComponent(e, allow.updateData)">
        <p class="mb-0">Cargue aquí el formato de edición masiva de datos de facturación de empresas.</p>
        <p class="font-weight-bold">El archivo solo puede ser *.xls o *.xlsx, según el siguiente <a class="lnk lnk-primary" :href="excelDocUrl" target="_blank" rel="noopener noreferrer">excel de ejemplo con formato válido.</a></p>
        <div class="row" >
          <div class="mt-0 pt-0 col-12 col-md-9">
            <b-col class="px-0 pb-1 pb-md-0 col" v-if="form.fileDataToUpdate" v-cloak>
              <div class="w-100 p-2 file-drop-area">
                <b-input-group class="invoice-list-area">
                  <b-form-input
                      placeholder="File name..."
                      v-model="form.fileDataToUpdate.name"
                      class="bg-gray-input border-0"
                  />
                  <b-input-group-append class="bg-gray-input">
                    <b-button variant="outline" class="border-0" size="sm" @click="removeFileDataToUpdate()" :disabled="loading.uploadDataToUpdate">
                      <feather-icon icon="XIcon" size="20"/>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>
            <b-col class="px-0 pb-1 pb-md-0 col" v-if="!form.fileDataToUpdate">
              <div class="file-drop-area" >
                <input type="file" name="fileDataToUpdate" id="fileDataToUpdate" class="file-input invisible" @change="changeFileDataToUpdate" accept=".xls, .xlsx"/>
                <label for="fileDataToUpdate" class="w-100 text-center cursor-pointer m-0">
                  <feather-icon v-if="!loading.uploadDataToUpdate" icon="UploadCloudIcon" size="30" class="mt-1" />
                  <p v-if="!loading.uploadDataToUpdate" class="mb-1">Subir Archivo</p>
                  <p class="mt-1 p-2" v-if="loading.uploadDataToUpdate"><b-spinner small  /> {{ $t('Enviando') }}</p>
                </label>
              </div>
            </b-col>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <b-button class="" block @click="uploadDataToUpdateFile()" variant="success" :disabled="loading.uploadDataToUpdate || !form.fileDataToUpdate">
              <b-spinner v-if="loading.uploadDataToUpdate" small></b-spinner>
              <span v-else>Enviar</span>
            </b-button>
          </div>
        </div>
        <div class="px-2" v-if="fileWithError">
          <p class="text-primary font-weight-bold pt-2">Listado de errores en el documento</p>
          <div class="error-table-container">
            <table-render :key="keyTableRender" :rows="rows" :schema="schema"/>
          </div>
        </div>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import BillingService from '../../billing.service'

export default {
  name: 'modal-masive-update',
  data() {
    return {
      formCountry: {},
      fieldsCountry: [],
      allow: {
        updateData: true
      },
      allowedTabsByCountry: Object.freeze({
        CL: ['updateData'],
        MX: [],
        CO: [],
        PE: [],
        AR: [],
        EC: []
      }),
      keyFormRenderCountryUpd: 0,
      excelDocUrl: null,
      fileWithError: false,
      keyTableRender: 0,
      schema: [
        {label: 'Detalle', key: 'detail'}
      ],
      rows: [],
      form: {
        fileDataToUpdate: null
      },
      loading: {
        uploadDataToUpdate: false
      },
      fields: [],
      baseService: new BaseServices(this),
      BillingService: new BillingService(this)
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries'
    })
  },
  watch: {
    countries () {
      this.setCountry()
    }
  },
  mounted() {
    this.setInitialData()
    this.excelDocUrl = `${this.BillingService.getCustomEnvironment('newyorkDocsCloud')}/excel-sample-massive-billing-data-updater/excel-sample-bulk-massive-billing-data-updater.xlsx`
  },
  methods: {
    setInitialData () {
      this.fieldsCountry = [
        {fieldType: 'FieldSelect', label: this.$t('País'), name: 'country', addFlags:true, useLabel: true, clearable:false, containerClass: 'col-md-4 container-info col-sm-6', change: this.checkAvailability}
      ]
      if (!!this.countries.length) this.setCountry()
    },
    setCountry () {
      this.setSelectOptions('country', { options: this.countries})
      this.formCountry.country = this.countries.find(item => item.code === 'CL')
      this.checkAvailability()
    },
    handleClickOnComponent (event, click) {
      if (click) return
      event.preventDefault()
    },
    checkAvailability () {
      Object.keys(this.allow).forEach(key => {
        this.allow[key] = this.allowedTabsByCountry[this.formCountry?.country.code].includes(key)
      })
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsCountry.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsCountry[index].options = options
        this.keyFormRenderCountryRed++
      }
    },
    uploadDataToUpdateFile () {
      if (!this.form.fileDataToUpdate) {
        this.$alert('Debes seleccionar un archivo para subir ')
        return
      }
      this.clearError()
      this.loading.uploadDataToUpdate = true
      const data = {
        file: this.form.fileDataToUpdate,
        countryCode: this.formCountry.country.code
      }
      this.baseService.callUploadFileCustomParams('updateMasiveManageBillingDataCompany', data)
        .then(response => {
          this.$success(this.$t('msg-exito-importar-archivo'))
          this.removeFileDataToUpdate()
          this.close()
        })
        .catch((err) => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message}))
          if (err.errors) {
            err.errors.map((e) => {
              this.rows.push({ detail: e.detail })
            })
            this.fileWithError = true
          }
        })
        .finally(() => {
          this.loading.uploadDataToUpdate = false
        })
    },
    changeFileDataToUpdate(e) {
      this.form.fileDataToUpdate = e.target.files[0]
    },
    removeFileDataToUpdate() {
      this.form.fileDataToUpdate = null
      this.keyFormRender++
      this.clearError()
    },
    clearError() {
      if (this.fileWithError) {
        this.rows = []
        this.fileWithError = false
      }
    },
    close() {
      this.removeFileDataToUpdate()
      this.$bvModal.hide('modal-masive-update')
    }
  }
}
</script>

<style lang="scss" scoped>
  div.file-drop-area {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    max-height: 300px;
    overflow-y: auto;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .invoice-list-area{
    padding-bottom: 8px;
  }
  .bg-gray-input {
    background-color: #f7f7f9 !important;
  }
  .add-adition-invoices{
    width: 100%;
    span {
      width: 100% !important;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.8rem;
    }
  }
</style>
