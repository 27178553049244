<template>
  <div>
    <p class="font-weight-bold mt-2">El archivo solo puede ser *.xls o *.xlsx, según el siguiente <a class="lnk lnk-primary" :href="excelDocUrl" target="_blank" rel="noopener noreferrer">excel de ejemplo con formato válido.</a></p>
    <div class="row" >
      <div class="mt-0 pt-0 col-12 col-md-9">
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="form.fileIndemnification" v-cloak>
          <div class="w-100 p-2 file-drop-area">
            <b-input-group class="invoice-list-area">
              <b-form-input
                  placeholder="File name..."
                  v-model="form.fileIndemnification.name"
                  class="bg-gray-input border-0"
              />
              <b-input-group-append class="bg-gray-input">
                <b-button variant="outline" class="border-0" size="sm" @click="removeFileIndemnification()" :disabled="loading.uploadData">
                  <feather-icon icon="XIcon" size="20"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="!form.fileIndemnification">
          <div class="file-drop-area" >
            <input type="file" name="filesIndemni" id="filesIndemni" class="file-input invisible" @change="changeFileIndemnification" accept=".xls, .xlsx"/>
            <label for="filesIndemni" class="w-100 text-center cursor-pointer m-0">
              <feather-icon v-if="!loading.uploadData" icon="UploadCloudIcon" size="30" class="mt-1" />
              <p v-if="!loading.uploadData" class="mb-1">Subir Archivo</p>
              <p class="mt-1 p-2" v-if="loading.uploadData"><b-spinner small  /> {{ $t('Enviando') }}</p>
            </label>
          </div>
        </b-col>
      </div>
      <div class="col-md-3 d-flex align-items-center">
        <b-button class="" block @click="uploadindemnification()" variant="success" :disabled="loading.uploadData || !form.fileIndemnification">
          <b-spinner v-if="loading.uploadData" small></b-spinner>
          <span v-else>Enviar</span>
        </b-button>
      </div>
    </div>
    <div class="px-2" v-if="fileWithError">
      <p class="text-primary font-weight-bold pt-2">Listado de errores en el documento</p>
      <div class="error-table-container-disable">
        <table-render :key="keyTableRender" :rows="rows" :schema="schema"/>
      </div>
    </div>
  </div>
</template>
<script>
import BillingService from '../../../../billing.service'
import BaseServices from '@/store/services/index'
import Swal from 'sweetalert2'

export default {
  name: 'upload-indemnification-manage',
  props: ['closeModal', 'blockTabs', 'selectedCountry'],
  data() {
    return {
      linkExcelRender: 0,
      excelDocUrl: null,
      errorsUploadBills: [],
      fileWithError: false,
      keyTableRender: 0,
      schema: [
        {label: 'Error', key: 'title_error'},
        {label: 'Detalle', key: 'detail', useDangerHTML: true}
      ],
      rows: [],
      form: {
        fileIndemnification: null
      },
      loading: {
        uploadData: false
      },
      fields: [],
      baseService: new BaseServices(this),
      BillingService: new BillingService(this),
      country: {}
    }
  },
  watch: {
    selectedCountry () {
      this.country = this.selectedCountry
    }
  },
  mounted() {
    this.excelDocUrl = `${this.BillingService.getCustomEnvironment('newyorkDocsCloud')}/excel-sample-indemnifications-bulk/indemnifications-format.xlsx`
    this.country = this.selectedCountry
  },
  methods: {
    uploadindemnification (allowAlreadyAdjustedDeliveries = 'false') {
      if (!this.form.fileIndemnification) {
        this.$alert('Debes seleccionar un archivo para subir ')
        return
      }
      this.blockParentTab(true)
      this.clearError()
      this.loading.uploadData = true
      const data = {
        file: this.form.fileIndemnification,
        countryCode: this.country.code,
        allowAlreadyAdjustedDeliveries
      }
      this.baseService.callUploadFileCustomParams('uploadIndemnification', data)
        .then(response => {
          this.$success(this.$t('msg-exito-importar-archivo'))
          this.removeFileIndemnification()
          this.close()
        })
        .catch((err) => {
          //En caso de que el error sea 409 es porque ya existen indemizaciones para ese envio y debe confirmar la subida.
          if (err.statusRep === 409) {
            this.alertDuplicetedFile(err.errors[0].detail)
          } else {
            const { statusRep, errors } = err

            const hasDetail = errors?.some(error => error.detail && error.title)
            // Selecciona el mensaje clave basado en el statusRep y la presencia de detalles.
            let messageKey = ''
            if (statusRep >= 500) {
              messageKey = 'Ha ocurrido un error inesperado, intente de nuevo o contacte con support.'
            } else if (statusRep >= 400 && hasDetail) {
              messageKey = 'Haz click en aceptar y mira los errores en la tabla.'
            } else if (statusRep >= 400) {
              messageKey = 'Ha ocurrido un error inesperado, intente de nuevo o contacte con support.'
            }
            this.$alert(this.$t(
              'msg-problema-cargar-datos',
              {
                code: err.code ? messageKey : err.message
              }))
            if (err.code && hasDetail) {
              const msg = Array.isArray(err.errors) && err.errors[0]?.detail ? err.errors.map((e) => Array.isArray(e.detail) ? e.detail.join('.<br>') : e.detail).join('.<br>') : Array.isArray(err.message) ? err.message.join('.<br>') : err.message
              this.rows.push({ title_error: err.errors[0]?.title, detail: msg })
              this.fileWithError = true
            }
          }
        })
        .finally(() => {
          this.loading.uploadData = false
          this.blockParentTab(false)
        })
    },
    alertDuplicetedFile(data) {
      Swal.fire({
        icon: 'warning',
        width: 500,
        heightAuto: false,
        html:
          `<div class="sweetAlert_indemnization-406">
          <p>Detectamos que se está intentado realizar <b class="text-danger">ajustes de indemnizaciones</b> que ya habían sido ajustados previamente.
          Se lista a continuación los IDs de envíos con indemnizaciones previamente ajustados:</p><p class="font-weight-bold border-bottom">ID_DELIVERY</p><div><ul>${data.map(id => `<li>${id}</li>`).join('')}</ul></div>
          <hr>
          <h5 class="text-primary">¿Está seguro que desea continuar con el proceso y cargar los ajustes de todas formas?</h5></div>
          `,
        customClass: {
          icon: 'sweetAlert_indemnization--icon',
          actions: 'sweetAlert_indemnization--actions'
        },
        allowOutsideClick: false,
        reverseButtons: true,
        focusCancel: true,
        showCancelButton: true,
        cancelButtonColor: '#18a689',
        confirmButtonColor: '#dc3741',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar de todas formas'
      }).then((result) => {
        //Si acepta se enviaran las indemnizaciones duplicadas
        if (result.isConfirmed) {
          this.uploadindemnification(true)
        }
      })
    },
    changeFileIndemnification(e) {
      this.form.fileIndemnification = e.target.files[0]
    },
    removeFileIndemnification() {
      this.form.fileIndemnification = null
      this.keyFormRender++
    },
    blockParentTab (val) {
      this.$emit('blockTabs', val)
    },
    clearError() {
      if (this.fileWithError) {
        this.rows = []
        this.fileWithError = false
      }
    },
    close() {
      this.removeFileIndemnification() //ver is relamente esot es necesario
      this.loading.uploadData = false
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  div.file-drop-area {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    max-height: 300px;
    overflow-y: auto;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .invoice-list-area{
    padding-bottom: 8px;
  }
  .bg-gray-input {
    background-color: #f7f7f9 !important;
  }
  .add-adition-invoices{
    width: 100%;
    span {
      width: 100% !important;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.8rem;
    }
  }
</style>
<style lang="scss" >

.sweetAlert_indemnization-406 {
  div {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    ul {
      column-rule: dotted 1px #333;
      list-style-type: none;
      columns: 3;
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 10px;
    }
  }
}
.sweetAlert_indemnization-406 > h5{
  margin-bottom: 1px;
}
.sweetAlert_indemnization-406--btn{
  margin: 5px !important;
}
.sweetAlert_indemnization--icon {
  margin: 0 auto;
}
.sweetAlert_indemnization--actions{
  margin-top: 0;
  justify-content: space-evenly;
}
.error-table-container{
  max-height: 200px !important;
  overflow-y: auto;
  overflow-x: hidden !important;
}
</style>
