<template>
  <b-modal
    id="modal-masive-disable"
    modal-class="dialog-800"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    class="modal-masive-disable"
    @close="close"
  >
    <template #modal-title>
      <h3>{{$t('Estado de desactivacion')}}</h3>
    </template>
    <form-render
      ref="formRenderStatusCompany"
      :form.sync="formCountry"
      :fields="fieldsCountry"
      :key="keyFormRenderCountryDisab"
    />
    <b-card>
      <div
        :class="[!allow.disabledData ? 'blocked-element' : '']"
        @click="e => handleClickOnComponent(e, allow.disabledData)"
      >
        <p class="mb-0">Cargue aquí el formato de edición masiva para la <b>desactivación</b> de empresas.</p>
        <p class="font-weight-bold">
          El archivo solo puede ser *.xls o *.xlsx, según el siguiente
          <a class="lnk lnk-primary" :href="excelDocUrl" target="_blank" rel="noopener noreferrer"
            >excel de ejemplo con formato válido.</a
          >
        </p>
        <div class="row">
          <div class="mt-0 pt-0 col-12 col-md-9">
            <b-col class="px-0 pb-1 pb-md-0 col" v-if="form.fileDataToDisable" v-cloak>
              <div class="w-100 p-2 file-drop-area">
                <b-input-group class="invoice-list-area">
                  <b-form-input
                    placeholder="File name..."
                    v-model="form.fileDataToDisable.name"
                    class="bg-gray-input border-0"
                  />
                  <b-input-group-append class="bg-gray-input">
                    <b-button
                      variant="outline"
                      class="border-0"
                      size="sm"
                      @click="removeFileDataToDisable()"
                      :disabled="loading.uploadDataToDisable"
                    >
                      <feather-icon icon="XIcon" size="20" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>
            <b-col class="px-0 pb-1 pb-md-0 col" v-if="!form.fileDataToDisable">
              <div class="file-drop-area">
                <input
                  type="file"
                  name="fileDataToDisable"
                  id="fileDataToDisable"
                  class="file-input invisible"
                  @change="changeFileDataToDisable"
                  accept=".xls, .xlsx"
                />
                <label for="fileDataToDisable" class="w-100 text-center cursor-pointer m-0">
                  <feather-icon v-if="!loading.uploadDataToDisable" icon="UploadCloudIcon" size="30" class="mt-1" />
                  <p v-if="!loading.uploadDataToDisable" class="mb-1">Subir Archivo</p>
                  <p class="mt-1 p-2" v-if="loading.uploadDataToDisable"><b-spinner small /> {{ $t('Enviando') }}</p>
                </label>
              </div>
            </b-col>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <b-button
              class=""
              block
              @click="uploadDataToDisableFile()"
              variant="success"
              :disabled="loading.uploadDataToDisable || !form.fileDataToDisable"
            >
              <b-spinner v-if="loading.uploadDataToDisable" small></b-spinner>
              <span v-else>Enviar</span>
            </b-button>
          </div>
        </div>
        <div class="px-2" v-if="fileWithError">
          <p class="text-primary font-weight-bold pt-2">Listado de errores en el documento</p>
          <div class="error-table-container-disable">
            <table-render :key="keyTableRender" :rows="rows" :schema="schema" />
          </div>
        </div>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import BillingService from '../../billing.service'

export default {
  name: 'modal-masive-disable',
  data() {
    return {
      formCountry: {},
      fieldsCountry: [],
      allow: {
        disabledData: true
      },
      allowedTabsByCountry: Object.freeze({
        CL: ['disabledData'],
        MX: [],
        CO: [],
        PE: [],
        AR: [],
        EC: []
      }),
      keyFormRenderCountryDisab: 0,
      excelDocUrl: null,
      fileWithError: false,
      keyTableRender: 0,
      schema: [
        {label: 'Detalle', key: 'detail'}
      ],
      rows: [],
      form: {
        fileDataToDisable: null
      },
      loading: {
        uploadDataToDisable: false
      },
      fields: [],
      baseService: new BaseServices(this),
      BillingService: new BillingService(this)
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries'
    })
  },
  watch: {
    countries () {
      this.setCountry()
    }
  },
  mounted() {
    this.setInitialData()
    this.excelDocUrl = `${this.BillingService.getCustomEnvironment('newyorkDocsCloud')}/excel-sample-companies-deactivation-bulk/formato-compañias-desactivacion.xlsx`
  },
  methods: {
    setInitialData () {
      this.fieldsCountry = [
        {fieldType: 'FieldSelect', label: this.$t('País'), name: 'country', addFlags:true, useLabel: true, clearable:false, containerClass: 'col-md-4 container-info col-sm-6', change: this.checkAvailability}
      ]
      if (!!this.countries.length) this.setCountry()
    },
    setCountry () {
      this.setSelectOptions('country', { options: this.countries})
      this.formCountry.country = this.countries.find(item => item.code === 'CL')
      this.checkAvailability()
    },
    handleClickOnComponent (event, click) {
      if (click) return
      event.preventDefault()
    },
    checkAvailability () {
      Object.keys(this.allow).forEach(key => {
        this.allow[key] = this.allowedTabsByCountry[this.formCountry?.country.code].includes(key)
      })
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsCountry.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsCountry[index].options = options
        this.keyFormRenderCountryRed++
      }
    },
    uploadDataToDisableFile () {
      if (!this.form.fileDataToDisable) {
        this.$alert('Debes seleccionar un archivo para subir ')
        return
      }
      this.clearError()
      this.loading.uploadDataToDisable = true
      const data = {
        file: this.form.fileDataToDisable,
        countryCode: this.formCountry.country.code
      }
      this.baseService.callUploadFileCustomParams('disableMasiveManageBillingDataCompany', data)
        .then(response => {
          this.$success(this.$t('msg-exito-desativacion-compania'))
          this.removeFileDataToDisable()
          this.close()
        })
        .catch((err) => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message}))
          if (err.errors) {
            err.errors.forEach((e) => {
              let message = ''
              Array.isArray(e.detail) ? e.detail.forEach(msg => message += `${msg}. `) : message = e.detail
              this.rows.push({ detail: message })
            })
            this.fileWithError = true
          }
        })
        .finally(() => {
          this.loading.uploadDataToDisable = false
        })
    },
    changeFileDataToDisable(e) {
      this.form.fileDataToDisable = e.target.files[0]
    },
    removeFileDataToDisable() {
      this.form.fileDataToDisable = null
      this.keyFormRender++
      this.clearError()
    },
    clearError() {
      if (this.fileWithError) {
        this.rows = []
        this.fileWithError = false
      }
    },
    close() {
      this.removeFileDataToDisable()
      this.$bvModal.hide('modal-masive-disable')
    }
  }
}
</script>

<style lang="scss" scoped>
 div.file-drop-area {
   border: 1px solid #d8d6de;
   border-radius: 0.357rem;
   max-height: 300px;
   overflow-y: auto;
   input.file-input {
     position: absolute;
     height: 0;
   }
 }
 .invoice-list-area{
   padding-bottom: 8px;
 }
 .bg-gray-input {
   background-color: #f7f7f9 !important;
 }
 .add-adition-invoices{
   width: 100%;
   span {
     width: 100% !important;
     font-size: 1rem;
     font-weight: 500;
     padding: 0.8rem;
   }
 }
 .error-table-container-disable{
   max-height: 300px !important;
   overflow-y: auto;
   overflow-x: hidden !important;
}
</style>
