<template>
  <div>
    <p class="text-md mt-2">En esta pestaña puede generar el archivo de facturación correspondiente a los servicios de Connect y Marketcenter.</p>
    <br/>
    <p>Seleccione el o los servicios que desea incluir en la descarga.</p>
    <br/>
    <div class="row" >
        <b-col md="6" class="mb-2 mt-2">
          <b-form-group
            label-for="carriersList"
            label="Mes a facturar"
          >
            <v-select
              id="carriersList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentMonth"
              :options="optionMonths"
              placeholder="Seleccione mes a facturar"
              label="text"
              required
            />
          </b-form-group>
        </b-col>
        <div class="col-12 mt-1 d-flex justify-content-start gap-2">
          <b-form-group>
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="allowExport"
              stacked
              :options="optionExports"
              name="flavour"
              required
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      <div class="col-md-3 mt-2">
        <b-button block @click="generateExportServices()" variant="success" :disabled="!isFormValid || loading.generatedExportService">
          <b-spinner v-if="loading.generatedExportService" small></b-spinner>
          <span v-else>Generar</span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import BillingService from '../../../../billing.service'
import BaseServices from '@/store/services/index'
import vSelect from 'vue-select'

export default {
  name: 'export-other-services',
  components: {
    vSelect
  },
  props: ['closeModal', 'blockTabs', 'selectedCountry'],
  data() {
    return {
      form: {
        fileCost: null
      },
      isCountryAviable: true,
      loading: {
        generatedExportService: false
      },
      baseService: new BaseServices(this),
      BillingService: new BillingService(this),
      allowExport: [],
      optionExports: [
        { text: 'Connect', value: 'checkConnect' },
        { text: 'MarketCenter', value: 'checkMarketcenter', disabled: true }
      ],
      country: {},
      currentMonth: null,
      monthConfig: {
        cutoffDay: 1, // Day of the month for cutoff
        cutoffHour: 10, // Hour in UTC for cutoff
        monthsToShow: 3 // Number of previous months to show
      }
    }
  },
  computed: {
    optionMonths() {
      const months = []
      const now = new Date()
      // Add current month with "En proceso" message
      //const currentMonthText = this.$moment(now).format('MMMM YYYY')
      //const currentMonthUpper = currentMonthText.charAt(0).toUpperCase() + currentMonthText.slice(1)
      //months.push({
      //  text: `${currentMonthUpper} (En proceso)`,
      //  value: this.$moment(now).format('YYYY-MM'),
      //  disabled: true
      //})

      // Add previous 3 months
      for (let i = 1; i <= 3; i++) {
        const previousDate = this.$moment(now).subtract(i, 'months')
        const monthText = previousDate.format('MMMM YYYY')
        const monthUpper = monthText.charAt(0).toUpperCase() + monthText.slice(1)
        months.push({
          text: monthUpper,
          value: previousDate.format('YYYY-MM')
        })
      }

      return months
    },
    isFormValid() {
      return this.currentMonth && this.allowExport.length > 0
    }
  },
  watch: {
    selectedCountry () {
      this.country = this.selectedCountry
      this.isCountryAviable = ['CL'].includes(this.selectedCountry.code)
    }
  },
  mounted() {
    this.isCountryAviable = ['CL'].includes(this.selectedCountry.code)
    this.country = this.selectedCountry
  },
  methods: {
    generateExportServices() {
      this.loading.generatedExportService = true
      const data = {
        countryCode: this.country.code,
        exportType: 'costs-other-services',
        optionYearMonth: this.currentMonth.value
      }
      const MARKETCENTER = 'checkMarketcenter'
      const CONNECT = 'checkConnect'

      //Validate if contain in the array the value of the checkbox and set 1 or 0
      const isMarketcenter = this.allowExport.includes(MARKETCENTER)
      const isConnect = this.allowExport.includes(CONNECT)
      data[MARKETCENTER] = isMarketcenter ? 1 : 0
      data[CONNECT] = isConnect ? 1 : 0
      const params = {}
      this.baseService.callUploadFileCustomParams('exportOtherServices', data, params)
        .then(response => {
          const html = `<p>${this.$t('msg-exito-importar-archivo')}</p><p>Código: ${response.meta.deliveryExportBatch.code} </p>`
          this.$success((this.selectedValidationsBETA === 'validateOnly') ? this.$t('msg-exito-validar-archivo') : html)
          this.close()
        })
        .catch((err) => {
          const title = err?.errors ? err.errors[0].title : 'Ocurrió un problema al generar la exportación'
          const detail = err?.errors ? err.errors[0].detail : 'Consulte con soporte por favor.'
          this.$alert(this.$t('msg-problema-personalizado', { titleErr: title, detailErr: detail }))
        })
        .finally(() => {
          this.loading.generatedExportService = false
        })
    },
    close() {
      this.loading.generatedExportService = false
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" >
  .sweetAlert_cost-406 {
  div {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    ul {
      list-style-type: none;
      column-rule: dotted 1px #333;
      columns: 2;
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
      text-align: start;
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 10px;
    }
  }
}
.sweetAlert_cost-406 > h5{
  margin-bottom: 1px;
}
.sweetAlert_cost-406--btn{
  margin: 5px !important;
}
.sweetAlert_cost--icon {
  margin: 0 auto;
}
.sweetAlert_cost--actions{
  margin-top: 0;
  justify-content: space-evenly;
}
.error-table-container{
  max-height: 200px !important;
  overflow-y: auto;
  overflow-x: hidden !important;
}
</style>
