<template>
  <div>
    <p class="font-weight-bold mt-2">Los archivos solo pueden ser *.xls o *.xlsx y el nombre debe ser el número del documento al que debe ser asignado según el siguiente <a class="lnk lnk-primary" :href="excelDocExample" target="_blank" rel="noopener noreferrer">formato de ejemplo.</a></p>
    <form-render :form.sync="form" :fields="fields" :key="keyFormRender" containerButtonsClass="col-sm-12" ref="formSendNotification"/>
    <div class="row">
      <div class="mt-0 pt-0 col-12 col-md-10">
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="form.fileNotification" v-cloak>
          <b-input-group class="w-100 p-2 file-drop-area">
            <b-form-input
                placeholder="File name..."
                v-model="form.fileNotification.name"
                required
                class="bg-gray-input border-0"
            />
            <b-input-group-append class="bg-gray-input">
              <b-button variant="outline" class="border-0" size="sm" @click="removeFileNotification()" :disabled="loading.uploadData">
                <feather-icon icon="XIcon" size="20"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="!form.fileNotification">
          <div class="file-drop-area" >
            <input type="file" name="file" id="file" class="file-input invisible" @change="changeFileNotification" accept=".xls, .xlsx"/>
            <label for="file" class="w-100 text-center cursor-pointer m-0">
              <feather-icon v-if="!loading.uploadData" icon="UploadCloudIcon" size="30" class="mt-1" />
              <p v-if="!loading.uploadData" class="mb-1">Subir Archivo</p>
              <p class="mt-1 p-2" v-if="loading.uploadData"><b-spinner small  /> {{ $t('Enviando') }}</p>
            </label>
          </div>
        </b-col>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <b-button class="" block @click="sendNotification" variant="success" :disabled="loading.uploadData || !form.fileNotification">
          <b-spinner v-if="loading.uploadData" small></b-spinner>
          <span v-else>Enviar</span>
        </b-button>
      </div>           
      <div class="px-2" v-if="rowWithError">
        <p class="mt-2 text-primary font-weight-bold">Listado de filas que no pudieron ser procesadas. No procesadas: {{ unprocessed }}/{{ countRow }}. Procesadas: {{ processed }}/{{ countRow }}</p>
        <div class="error-table-container">
          <table-render :key="keyTableRender" :rows="rows" :schema="schema"/>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import BillingService from '../../../../billing.service'

export default {
  name: 'send-notification-manage',
  props: ['closeModal', 'blockTabs', 'selectedCountry'],
  data() {
    return { 
      excelDocExample: null,
      form: {
        fileNotification: null
      },
      loading: {
        uploadData: false
      },
      fields: [],      
      keyTableRender: 0,
      processed: 0,
      countRow: 0,
      unprocessed: 0,
      schema: [
        {label: '# de fila excel', sortable: true, key: 'row', style: { width: '30%' }},
        {label: 'Error', key: 'msg'}
      ],
      rows: [],
      rowWithError: false,  
      keyFormRender: 0,
      country: {},
      baseService: new BaseServices(this),      
      BillingService: new BillingService(this)
    }
  },
  watch: {
    selectedCountry () {
      this.country = this.selectedCountry
    }
  },
  mounted() {   
    this.setInitialData()
    this.excelDocExample = `${this.BillingService.getCustomEnvironment('newyorkDocsCloud')}/excel-sample-notifications-bulk/ejemplo-notif-billing.xlsx`
    this.country = this.selectedCountry
  },
  methods: {
    setInitialData() {
      this.fields = [
        {fieldType: 'FieldInput', name: 'new_subject', label: 'Asunto (Campo opcional)', containerClass: 'col-sm-12 container-info font-weight-bold', clearable: false, placeholder: 'Informe detalle de facturas'},
        {fieldType: 'FieldTextarea', name: 'new_body', label: 'Cuerpo (Campo opcional)', containerClass: 'col-sm-12 container-info font-weight-bold', clearable: false, placeholder: 'Estimados,\n\nEstamos adjuntando detalle de la subida de facturas masiva', rows: 4}
      ]
    },
    blockParentTab (val) {
      this.$emit('blockTabs', val)
    },
    sendNotification () {
      if (!this.form.fileNotification) {
        this.$alert('Debes seleccionar un archivo para subir')
        return
      }
      const fileNameParts = this.form.fileNotification.name.split('.')
      const fileExtension = fileNameParts.pop().toLowerCase()
      if (!['xls', 'xlsx'].includes(fileExtension)) {
        return this.$alert(`El archivo debe ser de extension xls o xlsx, extension actual de archivo ${fileExtension}`)
      }

      // validacion para solo permitir 30mb en el archivo
      // 1Mb = 1000000 Byte
      const fileSizeMb = (this.form.fileNotification.size / 1000000).toFixed(2)
      if (fileSizeMb > 30) {
        return this.$alert(`El archivo debe tener un peso máximo de 30 Mb, peso actual del archivo ${fileSizeMb} Mb`)
      }
      if (this.rowWithError) { // para eliminar los datos de error en caso de que suban nuevamente
        this.rows = []
        this.rowWithError = false
      }
      this.blockParentTab(true)
      this.loading.uploadData = true
      const data = {
        email: this.$session.get('cas_user').email,
        new_subject: this.form.new_subject || '',
        new_body: this.form.new_body || '',
        file: this.form.fileNotification,
        countryCode: this.country.code
      }
      this.fields.map(el => {
        this.setFieldsProperties(this.fields, el.name, 'disabled', true)
      })
      this.baseService.callUploadFileCustomParams('sendNotificationBulk', data)
        .then(response => {
          if (response.code === 'warning') {      
            this.processed = response.meta.processed
            this.countRow = response.meta.count
            this.unprocessed = response.meta.unprocessed
            this.rowWithError = true
            response.data.map(file => {
              if (file.error) {
                this.rows.push({ msg: file.error, row: file.rowNumber})
              }
            })
            this.$warning('Archivo procesado excluyendo las filas con errores, por favor verificar en la tabla.', () => null, 'Procesado con exclusión', 'Revisar')
          }
          else {
            this.$success(this.$t('msg-exito-enviar-notificaciones'))
            this.close()
          }
        })
        .catch((err) => {
          const {subtitle, detail} = this.handlerStandartError(err)
          this.$alert(`${subtitle}<small>${detail}</small>`, null, 'Ha habido un problema al enviar las notificaciones')
          // Before BrahyanPro
          // const message = Array.isArray(err.errors) && err.errors[0]?.detail ? err.errors.map((e) => Array.isArray(e.detail) ? e.detail.join('.<br>') : e.detail).join('.<br>') : err.message
          // this.$alert(this.$t('msg-problema-enviar-notificaciones', {error: message}))
        })
        .finally(() => {
          this.blockParentTab(false)
          this.loading.uploadData = false
          this.fields.map(el => {
            this.setFieldsProperties(this.fields, el.name, 'disabled', false)
          })
        })      
    },
    changeFileNotification(e) {
      this.form.fileNotification = e.target.files[0]
      this.keyFormRender++
    },
    removeFileNotification() {
      this.form.fileNotification = null
      this.keyFormRender++
    },
    setFieldsProperties (fields, name, propertyField, value) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index][propertyField] = value
        this.keyFormRender++
      }
    },
    close() {
      this.removeFileNotification()
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  div.file-drop-area {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .bg-gray-input {
    background-color: #f7f7f9 !important
  }
  .error-table-container{
    max-height: 200px !important;
    overflow-y: auto;
    overflow-x: hidden !important;
 }
</style>