<template>
  <div>
    <b-card>
      <b-button
        variant="warning"
        class="manage-button"
        v-b-tooltip.hover
        title="Crear nuevo contacto de empresa"
        @click="openModalCreateManage"
        ><feather-icon icon="PlusIcon" />{{ $t("Nuevo registro") }}</b-button
      >
      <filter-swapper
        :trigger="selectedRows.length === 0"
        :buttons="buttons"
        :controlHeightButtons="controlHeight"
      >
        <template #slot1>
          <form-render
            :form.sync="formFilter"
            :key="keyFormRender"
            :fields="fields"
            @send="filterList"
            :buttonSend="buttonSend"
            ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-2 col-lg-2 col-xl-3 container-button mt-2"
          >
            <template #buttons>
              <b-button
                variant="outline-light"
                v-b-tooltip.hover
                title="Limpiar filtros"
                class="ml-1"
                @click="cleanFilter"
                ><feather-icon icon="RefreshCwIcon"
              /></b-button>
            </template>
            <template #extra>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-5 mt-2">
                <div class="row" style="float: right">
                  <div class="col" style="float: right">
                    <b-button @click="downloadExcel" variant="success">
                      <feather-icon v-if="!loading.excel" icon="DownloadIcon"/>
                      <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>
                      {{$t('Exportar a excel')}}
                    </b-button>
                  </div>
                </div>
              </div>
            </template>
          </form-render>
          <filter-select-group :options="optionsValues" :onChange="handleChangeFilter" :loadingStatus="statusRequest" label="Filtrar por:"/>
        </template>
      </filter-swapper>


      <table-render
        :key="keyTableRender"
        v-if="!loading.first"
        id="table_folders"
        :rows="rows"
        :schema="schema"
        :actions="actions"
        showCheckboxes="true"
        :selectedRows.sync="selectedRows"
        :loading="loading.billing"
        :sortByCustom="orderBy"
      />

      <pagination
        v-if="!loading.first"
        :pagination="pagination"
        :noDigits="false"
        :showSize="true"
        :disabled="loading.billing"
      />
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{}"
        />
      </div>
    </b-card>
    <div>
      <b-dropdown variant="success" toggle-class="text-decoration-none" menu-class="btn-uploadFiles-list" class="rounded-circle m-1 p-0 btn-uploadFiles-modal" no-caret dropup>
        <template #button-content>
          <feather-icon icon="DollarSignIcon" size="23"/>
        </template>
        <b-dropdown-item @click="() => openModal(modalNames.billing)">{{$t('Ciclo de facturación')}}</b-dropdown-item>
        <b-dropdown-item @click="() => openModal(modalNames.update)">{{$t('Datos facturación')}}</b-dropdown-item>
        <b-dropdown-item @click="() => openModal(modalNames.disable)">{{$t('Estado de desactivacion')}}</b-dropdown-item>
        <b-dropdown-item @click="() => openModal(modalNames.recaudos)">{{$t('Recaudos entrega')}}</b-dropdown-item>
      </b-dropdown>
    </div>
    <modal-upload-files/>
    <modal-masive-update/>
    <modal-masive-disable/>
    <modal-recaudos/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BillingService from '../billing.service'
import ModalUploadFiles from './upload-files/ModalUploadFiles.vue'
import ModalMasiveUpdate from './upload-files/ModalMasiveUpdate.vue'
import ModalMasiveDisable from './upload-files/ModalMasiveDisable.vue'
import ModalRecaudos from './upload-files/ModalRecaudos.vue'

export default {
  name: 'crud-billing',
  components: { ModalUploadFiles, ModalMasiveUpdate, ModalMasiveDisable, ModalRecaudos},

  data() {
    return {
      selectedRows: [],
      formFilter: {},
      payloadFilter: {},
      loading: {
        first: true,
        billing: true,
        excel: false
      },
      buttons: [],
      environment: null,
      rows: [],
      schema: [],
      fields: [],
      actions: [],
      pagination: { page: 1, limit: 10, total: 0, loading: false },
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      keyFormRender: 0,
      keyTableRender: 0,
      enableExport: false,
      manageDataervice: new BillingService(this),
      sortProps: null,
      valueTags: [],
      statusRequest: null,
      modalNames: Object.freeze({
        billing: 'modal-upload-files',
        update: 'modal-masive-update',
        disable: 'modal-masive-disable',
        recaudos: 'modal-recaudos'
      }),
      optionsValues: [
        {
          title: 'Pais',
          values: [
            { item: {value: 5, group: 'countryId', name: 'Argentina'} },
            { item: {value: 1, group: 'countryId', name: 'Chile'} },
            { item: {value: 3, group: 'countryId', name: 'Colombia'} },
            { item: {value: 4, group: 'countryId', name: 'Perú'} },
            { item: {value: 2, group: 'countryId', name: 'México'} }
          ]
        },
        {
          title: 'Tipo',
          values: [
            { item: {value: 1, group: 'typeId', name: 'Marketplace'} },
            { item: {value: 3, group: 'typeId', name: 'Seller'} },
            { item: {value: 4, group: 'typeId', name: 'Empresa'} }
          ]
        },
        {
          title: 'Estado',
          values: [
            { item: {value: 0, group: 'isActive', name: 'Inactivo'} },
            { item: {value: 1, group: 'isActive', name: 'Activo'} }
          ]
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      manageData: 'getManageData'
    })
  },

  watch: {
    manageData() {
      this.rows = this.manageData.rows.map(manageData => {
        return {
          ...manageData,
          id: manageData.id,
          company_id: manageData.id,
          taxIdentificationNumber: manageData.businessData?.taxIdentificationNumber,
          company_type: manageData.type?.platformName,
          company_name: manageData.name,
          business_name: manageData.businessData?.businessName ? manageData.businessData.businessName : '',
          updated_at: manageData.businessData?.updatedAt ? this.$options.filters.dbDateUTCToGMTlocal(manageData.businessData.updatedAt) : ''
        }
      })
      this.loading.first = false
      this.loading.billing = false
      this.selectedRows = []
      this.pagination.page = this.manageData.pagination.current_page
      this.pagination.total = this.manageData.pagination.total_items
      this['pagination.limit'] = this.manageData.pagination.total_pages
    },
    'pagination.page'() {
      this.getManageData()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getManageData()
    }
  },

  mounted() {
    this.setInitialData()
    this.getAllInitialData()
  },

  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', name: 'search', label: 'ID/Código/Identificador Fiscal/Nombre/Razón Social', containerClass: 'col-sm-12 col-md-4 container-info' }
      ]
      this.schema = [
        { label: 'ID', key: 'company_id', customSort: 'id' },
        { label: 'Código', key: 'code', customSort: 'code' },
        { label: 'Tipo', key: 'company_type' },
        { label: 'Identificador Fiscal', key: 'taxIdentificationNumber' },
        { label: 'Nombre', key: 'company_name', customSort: 'name' },
        { label: 'Razón Social', key: 'business_name' },
        { label: 'Última modificación', key: 'updated_at' },
        { label: 'Acciones', key: 'actions', class: ['text-center m-content'] }
      ]
      // Como ya no tiene un id la información de la empresa se pasa por un useSlot el valor company_id a las acciones
      this.actions = [
        { action: id => this.validateBillingData(id), icon: 'Edit2Icon', color: 'primary', text: 'Editar empresa' },
        { action: id => this.gotoBillingPlatform(id), icon: 'FileIcon', color: 'primary', text: 'Facturas' },
        { action: id => this.confirmDeleteManage(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }
      ]
      this.buttons = [{ name: 'delete', text: 'Eliminar', color: 'danger', icon: 'TrashIcon', action: this.confirmDeleteMultipleManage }]
      // seteamos el environment desde billing.service
      this.environment = this.manageDataervice.setEnvironment()
    },
    validateBillingData (rowId) {
      const companyData = this.hasBusinessData(rowId)
      companyData.compBusinessData ? this.gotoEdit(companyData.compId) : this.$yesno(this.$t('msg-alerta-editar-no-manage-data'), () => this.gotoEdit(companyData.compId))
    },
    gotoEdit (id) {
      this.m_openWinBlank(`/manage-billing-data/${id}`)    
    },
    gotoBillingPlatform(id) {
      const companyData = this.hasBusinessData(id)
      companyData.compBusinessData ? this.m_openWinBlank(`/billing/${companyData.compId}`) : this.$info(this.$t('msg-alerta-no-invoice-allowed'), () => null, 'Aviso')
    },
    hasBusinessData (rowId) {
      const {id, businessData} = this.rows.find(el => el.id === rowId)
      return {
        compId: id,
        compBusinessData: businessData
      }
    },
    filterList(form) {
      this.payloadFilter = {}
      Object.keys(form).map(key => {
        this.payloadFilter[key] = form[key].id || form[key]
      })
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getManageData()
    },
    orderBy (sortAs, type) {
      this.sortProps = type ? type === 'desc' ? `-${sortAs}` : sortAs : null
      this.getManageData()
    },
    cleanFilter() {
      this.formFilter = {}
      this.keyFormRender++
      this.filterList(this.formFilter)
    },
    openModalCreateManage() {
      this.m_openWinBlank('/manage-billing-data-create')
    },
    getAllInitialData() {
      this.getManageData()
    },
    getManageData() {
      this.selectedRows = []
      this.loading.billing = true
      const queryParams = {
        'filter[businessDataEnabled]': 1,
        'page': this.pagination.page,
        'size': this.pagination.limit,
        ...this.payloadFilter
      }
      if (this.sortProps) queryParams.sort = this.sortProps
      if (this.valueTags.length) {
        this.statusRequest = 'load'
        this.valueTags.forEach(val => {
          queryParams[`filter[${val.group}]`] = val.value
        })
      }
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'getManageBillingData', queryParams,
        onSuccess: () => {
          if (!!this.valueTags.length) this.statusRequest = 'success'
        },
        onError: (err) => {
          if (!!this.valueTags.length) this.statusRequest = 'error'
          this.handleErrorBussinessData(err)
        }
      })

    },
    handleErrorBussinessData(err) {
      const {subtitle, detail} = this.handlerStandartError(err)
      this.$alert(`${subtitle}<small>${detail}</small>`, null, 'Error al cargar los datos')
      // Before Brahyanpro
      // const message = Array.isArray(err) && err?.errors[0]?.detail ? err.errors.forEach((e) => Array.isArray(e.detail) ? e.detail.join('.<br>') : e.detail).join('.<br>') : err.message
      // this.$alert(this.$t('msg-problema-cargar-datos', { code: message }))
    },
    handleChangeFilter (values) {
      this.valueTags = values
      this.getManageData()
    },
    confirmDeleteManage(_id) {
      const companyData = this.hasBusinessData(_id)
      companyData.compBusinessData ? this.$yesno(this.$t('msg-pregunta-eliminar-elemento'), () => this.deleteManage(_id)) : this.$info(this.$t('msg-alerta-no-invoice-allowed-delete'), () => null, 'Aviso')
    },
    deleteManage(id, showAlerts = true) {
      const row = this.rows.find(el => el.id === id)
      this.$store.dispatch('fetchService', { name: 'deleteManageBillingDataCompany', params: { company_id: row.id }, onSuccess: this.onSuccessDeleteManage })
    },
    onSuccessDeleteManage() {
      this.getManageData()
      this.$success(this.$t('msg-exito-eliminar-elemento'))
    },
    confirmDeleteMultipleManage() {
      this.$yesno(this.$t('msg-pregunta-eliminar-seleccionados'), this.deleteMultipleManage)
    },
    deleteMultipleManage() {
      const services = this.selectedRows.map(id => {
        const row = this.rows.find(el => el.id === id)
        return { name: 'deleteManageBillingDataCompany', params: { company_id: row.id } }
      })
      this.$store.dispatch('fetchMultipleServices', { services, showPackSuccess: true, onSuccess: this.onSuccessdeleteMultipleManage })
    },
    onSuccessdeleteMultipleManage() {
      this.getManageData()
      this.$success(this.$t('msg-exito-eliminar-seleccionados'))
    },
    openModal (name) {
      this.$bvModal.show(name)
    },
    downloadExcel() {
      this.loading.excel = true
      this.$store.dispatch('downloadDataExcel', { onSuccess: this.onLoadedExcel })
    },
    onLoadedExcel () {
      this.loading.excel = false
    }
  }
}
</script>

<style lang="scss">
.manage-button {
  top: -3.5rem !important;
  right: 0rem !important;
  position: absolute !important;
  z-index: 9;
}
.btn-uploadFiles-modal{
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  button {
    border-radius: 50% !important;
    width: 50px;
    height: 50px;
    padding: 0;
  }
}
.btn-uploadFiles-list{
  top: 2rem !important;
  left: -9.5rem !important;
  border: 1.5px solid #1B4284 !important;
  li > {
    a{
      font-weight: bold !important;
      color: #1B4284 !important;
    }
    a:active {
      color: white !important;
    }
  }
}
[dir=ltr] .btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]).btn-uploadFiles-list{
  border-left-color: #1B4284 !important;
  border-right-color: #1B4284 !important;
}
.select-optgroup-title{
  opacity: 1 !important;
  font-weight: bold !important;
  color: blue;
}
.badge-cnt-filter{
  margin: 5px 5px 0 5px;
}
.blocked-element{
  opacity: 0.5;
}
.blocked-element, .blocked-element p.font-weight-bold a {
  cursor: not-allowed !important;
}
</style>
